import { message, Table, Icon, Button, Input, Radio, Spin } from 'antd';
import React, { Component } from 'react';
import Header from './Header';
import axios from "axios";
import Modal from 'antd/lib/modal/Modal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
var initialState = {
  adminData:[],
  showAddModal:false,
  isActive:'Yes',
  spinner:false,
  email:"",
  password:"",
  name:"",
  designation:"",
  importMasterData:false,
  importWorkOrders:false,
  manageAdmins:false,
  manageVendors:false,
  isEditing:false,
  admin_table_id:0
}
class AdminMaster extends Component {
  state = {
      adminData:[],
      showAddModal:false,
      isActive:'Yes',
      spinner:false,
      email:"",
      password:"",
      name:"",
      designation:"",
      importMasterData:false,
      importWorkOrders:false,
      manageAdmins:false,
      manageVendors:false,
      isEditing:false,
      admin_table_id:0
  }

  componentDidMount(){
    this.getAllAdmins()
  }
  getAllAdmins = () => {
      let token = localStorage.getItem('token')
      axios.get(process.env.REACT_APP_BASE_URL+"/admin/get?token="+token).then((res)=>{
			console.log(res)
			if(res.data.status===1){
                let array = res.data.data
                for(let i=0;i<array.length;i++){
                    array[i].srNo=i+1
                    array[i].isActive = (array[i].is_active==="1") ? "Yes" : "No"
                }
                this.setState({adminData:array})
            }else{
                message.error(res.data.message)
            }
		})
  }
  editAdmin = (admin) => {
    console.log(admin)
    this.setState({
      email:admin.email_address,
      name:admin.name,
      designation:admin.designation,
      isActive:admin.isActive,
      admin_table_id:admin.admin_table_id,
      importMasterData:admin.permissions.includes('Import Excel for Master Data'),
      importWorkOrders:admin.permissions.includes('Import Excel for Work Orders'),
      manageAdmins:admin.permissions.includes('Manage Admins'),
      manageVendors:admin.permissions.includes('Manage Vendors'),
      showAddModal:true,
      isEditing:true
    })
  }
  deleteAdmin = (admin) => {
    if(window.confirm("Are you sure you want to delete this admin?")){
      let admin_id = admin.admin_table_id
      let token = localStorage.getItem('token')
      const params = new URLSearchParams()
      params.append('admin_id',admin_id)
  
      axios.post(process.env.REACT_APP_BASE_URL+"/admin/delete?token="+token,params,{
          headers:{
              'Content-Type':'application/x-www-form-urlencoded'
          }
      }).then((res)=>{
          console.log(res)
          if(res.data.status===1){
              message.success(res.data.message)
              this.setState( initialState,() => this.getAllAdmins())
          }else{
              message.error(res.data.message)
          }
      })
    }
  }
  saveNewAdmin = () => {
    if(this.state.email==="" || this.state.password===""){
        message.error("Please fill all fields")
        return
    }
    let token = localStorage.getItem('token')
    let permissions = this.getPermissionsString()
    this.setState({ spinner: true })
    const params = new URLSearchParams()
    params.append('name',this.state.name)
    params.append('designation',this.state.designation)
    params.append('is_active',(this.state.isActive==="Yes") ? "1" : "0")
    params.append('email', this.state.email)
    params.append('password', this.state.password)
    params.append('permissions',permissions)

    axios.post(process.env.REACT_APP_BASE_URL+"/admin/add?token="+token,params,{
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }).then((res)=>{
        console.log(res)
        if(res.data.status===1){
            message.success(res.data.message)
            this.setState(initialState,() => this.getAllAdmins())
        }else{
            this.setState({ spinner: false })
            message.error(res.data.message)
        }
    })
  }
  updateAdmin = () => {
    if(this.state.email===""){
      message.error("Please fill all fields")
      return
    }
    let token = localStorage.getItem('token')
    let permissions = this.getPermissionsString()
    this.setState({ spinner: true })
    const params = new URLSearchParams()
    params.append('name',this.state.name)
    params.append('designation',this.state.designation)
    params.append('is_active',(this.state.isActive==="Yes") ? "1" : "0")
    params.append('email', this.state.email)
    params.append('password', this.state.password)
    params.append('permissions',permissions)
    params.append('admin_table_id',this.state.admin_table_id)
    axios.post(process.env.REACT_APP_BASE_URL+"/admin/update?token="+token,params,{
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }).then((res)=>{
        console.log(res)
        if(res.data.status===1){
            message.success(res.data.message)
            this.setState(initialState,() => this.getAllAdmins())
        }else{
            this.setState({ spinner: false })
            message.error(res.data.message)
        }
    })
  }
  addNewAdmin = () => {
      this.setState({showAddModal:true})
  }
  handleAddModalCancel = () => {
      this.setState(initialState,()=>this.getAllAdmins())
  }
  handleTextChange = (e) => {
      this.setState({
          [e.target.name]:e.target.value
      })
  }
  onRadioValueChange = (e) => {
    this.setState({
        isActive:e.target.value
    })
  }
  getPermissionsString = () => {
    let permissions = ""
    if(this.state.importMasterData===true){
      permissions=(permissions==="") ? "Import Excel for Master Data" : permissions+", Import Excel for Master Data"
    }
    if(this.state.importWorkOrders===true){
      permissions=(permissions==="") ? "Import Excel for Work Orders" : permissions+", Import Excel for Work Orders"
    }
    if(this.state.manageAdmins===true){
      permissions=(permissions==="") ? "Manage Admins" : permissions+", Manage Admins"
    }
    if(this.state.manageVendors===true){
      permissions=(permissions==="") ? "Manage Vendors" : permissions+", Manage Vendors"
    }
    return permissions
  }
  onChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]:e.target.checked,
    })
  }
  render() {
    const adminMasterColumns = [
        {
		    title: 'Sr No',
		    dataIndex: 'srNo',
		    key: 'srNo',
		    width: 70
		  },
		  {
		    title: 'Name',
		    dataIndex: 'name',
		    key: 'name',
		    width: 320
		  },
		  {
		    title: 'Designation',
		    dataIndex: 'designation',
		    key: 'designation',
		    width: 320
		  },
		  {
		    title: 'Email Address',
		    dataIndex: 'email_address',
		    key: 'email_address',
		    width: 320
		  },
		  {
		    title: 'Permissions',
		    dataIndex: 'permissions',
		    key: 'permissions',
		    width: 320
		  },
		  {
		    title: 'Is Active?',
		    dataIndex: 'isActive',
		    key: 'isActive',
		    width: 320
		  },
		  {
		    title: 'Action',
		    dataIndex: 'action',
		    key: 'action',
		    width: 320,
            render: (text,record,index) => { 
			    return (
			      <div>
			      	<button className="action_btns" style={{marginRight:5}} onClick={()=>this.editAdmin(record)}><i class='bx bx-edit-alt'></i></button>
			      	<button className="action_btns_delete" onClick={()=>this.deleteAdmin(record)}><i class='bx bx-trash-alt'></i></button>
			      </div>
			    )
			},
		  },
    ]
    const radioStyle = {
        display: 'inline',
        height: '30px',
        lineHeight: '30px',
        marginLeft: '30px'
      };
    return (
      <div>
          <Header/>
          <div  className="container">
            <div className="header_menu">
                <button style={{marginTop:20}} className="add_new_button" type="button" onClick={this.addNewAdmin}>Add New Admin</button>
            </div>
            <Table 
                style={{marginTop:20}}
                bordered 
                columns={adminMasterColumns} 
                dataSource={this.state.adminData}
                />
            </div>
            <Modal
                title='Add Admin'
                visible={this.state.showAddModal}
                footer={
                  <Button type='primary' onClick={(this.state.isEditing) ? this.updateAdmin:this.saveNewAdmin} loading={this.state.spinner}>
                    {(this.state.isEditing) ? "Update" : "Add"}
                  </Button>
                }
                onCancel={this.handleAddModalCancel}
            >
                <span>Name</span>
                <Input
                    placeholder='Name'
                    name="name"
                    value={this.state.name}
                    onChange={this.handleTextChange}
                />
                <span>Designation</span>
                <Input
                    placeholder='Designation'
                    name="designation"
                    value={this.state.designation}
                    onChange={this.handleTextChange}
                />
                <span>Email Address</span>
                <Input
                    placeholder='Email Address'
                    name="email"
                    value={this.state.email}
                    onChange={this.handleTextChange}
                />
                <span>Password</span>
                <Input
                    placeholder='Password'
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleTextChange}
                />
                <span>Is Active?</span>
                <Radio.Group onChange={this.onRadioValueChange} value={this.state.isActive}>
                    <Radio style={radioStyle} value="Yes">
                        Yes
                    </Radio>
                    <Radio style={radioStyle} value="No">
                        No
                    </Radio>
                </Radio.Group>
                <br></br>
                <span>Permissions</span>
                <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.importMasterData}
                  name="importMasterData"
                  defaultChecked={this.state.importMasterData}>Import Excel for Master Data</Checkbox>
                  <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.importWorkOrders}
                  name="importWorkOrders"
                  defaultChecked={this.state.importWorkOrders}>Import Excel for Work Orders</Checkbox>
                  <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.manageAdmins}
                  name="manageAdmins"
                  defaultChecked={this.state.manageAdmins}>Manage Admins</Checkbox>
                  <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.manageVendors}
                  name="manageVendors"
                  defaultChecked={this.state.manageVendors}>Manage Vendors</Checkbox>
            </Modal>
      </div>
    );
  }
}
export default AdminMaster