import { message, Table, Icon, Button, Input, Radio, Spin } from 'antd';
import React, { Component } from 'react';
import Header from './Header';
import axios from "axios";
import Modal from 'antd/lib/modal/Modal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
var initialState = {
  vendorData:[],
  showAddModal:false,
  isActive:'Yes',
  spinner:false,
  vendor_username:"",
  vendor_password:"",
  permissions:"",
  vendor_address:"",
  name:"",
  frame:false,
  frontWheel:false,
  rearWheel:false,
  frFairingAssembly:false,
  paintedParts:false,
  isEditing:false,
  vendor_table_id:0
}
class VendorMaster extends Component {
  
  state = {
      vendorData:[],
      showAddModal:false,
      isActive:'Yes',
      spinner:false,
      vendor_username:"",
      vendor_password:"",
      permissions:"",
      vendor_address:"",
      name:"",
      frame:false,
      frontWheel:false,
      rearWheel:false,
      frFairingAssembly:false,
      paintedParts:false,
      isEditing:false,
      vendor_table_id:0
  }

  componentDidMount(){
    this.getAllVendors()
  }
  getAllVendors = () => {
      let token = localStorage.getItem('token')
      axios.get(process.env.REACT_APP_BASE_URL+"/vendor/get?token="+token).then((res)=>{
			console.log(res)
			if(res.data.status===1){
                let array = res.data.data
                for(let i=0;i<array.length;i++){
                    array[i].srNo=i+1
                    array[i].isActive = (array[i].is_active==="1") ? "Yes" : "No"
                }
                this.setState({vendorData:array})
            }else{
                message.error(res.data.message)
            }
		})
  }
  editVendor = (vendor) => {
    this.setState({
      isEditing:true,
      showAddModal:true,
      name:vendor.vendor_name,
      vendor_address:vendor.vendor_address,
      vendor_table_id:vendor.vendor_table_id,
      isActive:vendor.isActive,
      vendor_username:vendor.vendor_username,
      frame:vendor.permissions.includes('Frame Details'),
      frontWheel:vendor.permissions.includes('Wheel Details'),
      frFairingAssembly:vendor.permissions.includes('Fairing Details'),
      paintedParts:vendor.permissions.includes('Painted Parts Details')
    })
  }
  deleteVendor = (vendor) => {
    if(window.confirm("Are you sure you want to delete this vendor?")){
      let vendor_id = vendor.vendor_table_id
      let token = localStorage.getItem('token')
      const params = new URLSearchParams()
      params.append('vendor_id',vendor_id)
  
      axios.post(process.env.REACT_APP_BASE_URL+"/vendor/delete?token="+token,params,{
          headers:{
              'Content-Type':'application/x-www-form-urlencoded'
          }
      }).then((res)=>{
          console.log(res)
          if(res.data.status===1){
              message.success(res.data.message)
              this.setState( initialState,() => this.getAllVendors())
          }else{
              message.error(res.data.message)
          }
      })
    }
  }
  saveNewVendor = () => {
    if(this.state.vendor_username==="" || this.state.vendor_password===""){
        message.error("Please fill all fields")
        return
    }
    let token = localStorage.getItem('token')
    let permissions = this.getPermissionsString()
    this.setState({ spinner: true })
    const params = new URLSearchParams()
    params.append('vendor_name',this.state.name)
    params.append('vendor_address',this.state.vendor_address)
    params.append('is_active',(this.state.isActive==="Yes") ? "1" : "0")
    params.append('vendor_username', this.state.vendor_username)
    params.append('vendor_password', this.state.vendor_password)
    params.append('permissions', permissions)

    axios.post(process.env.REACT_APP_BASE_URL+"/vendor/add?token="+token,params,{
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }).then((res)=>{
        console.log(res)
        if(res.data.status===1){
            message.success(res.data.message)
            this.setState( initialState,() => this.getAllVendors())
        }else{
            this.setState({ spinner: false })
            message.error(res.data.message)
        }
    })
  }
  updateVendor = () => {
    if(this.state.vendor_username===""){
      message.error("Please fill all fields")
      return
    }
    let token = localStorage.getItem('token')
    let permissions = this.getPermissionsString()
    this.setState({ spinner: true })
    const params = new URLSearchParams()
    params.append('vendor_name',this.state.name)
    params.append('vendor_address',this.state.vendor_address)
    params.append('is_active',(this.state.isActive==="Yes") ? "1" : "0")
    params.append('vendor_username', this.state.vendor_username)
    params.append('vendor_password', this.state.vendor_password)
    params.append('permissions', permissions)
    params.append('vendor_table_id',this.state.vendor_table_id)
    axios.post(process.env.REACT_APP_BASE_URL+"/vendor/update?token="+token,params,{
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }).then((res)=>{
        console.log(res)
        if(res.data.status===1){
            message.success(res.data.message)
            this.setState( initialState,() => this.getAllVendors())
        }else{
            this.setState({ spinner: false })
            message.error(res.data.message)
        }
    })
  }
  getPermissionsString = () => {
    let permissions = ""
    if(this.state.frame===true){
      permissions=(permissions==="") ? "Frame Details" : permissions+", Frame Details"
    }
    if(this.state.frontWheel===true){
      permissions=(permissions==="") ? "Wheel Details" : permissions+", Wheel Details"
    }
    if(this.state.rearWheel===true){
      permissions=(permissions==="") ? "Rear Wheel" : permissions+", Rear Wheel"
    }
    if(this.state.frFairingAssembly===true){
      permissions=(permissions==="") ? "Fairing Details" : permissions+", Fairing Details"
    }
    if(this.state.paintedParts===true){
      permissions=(permissions==="") ? "Painted Parts Details" : permissions+", Painted Parts Details"
    }
    return permissions
  }
  addNewVendor = () => {
      this.setState({showAddModal:true})
  }
  handleAddModalCancel = () => {
      this.setState(initialState,()=>this.getAllVendors())
  }
  handleTextChange = (e) => {
      this.setState({
          [e.target.name]:e.target.value
      })
  }
  onRadioValueChange = (e) => {
    this.setState({
        isActive:e.target.value
    })
  }
  onChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]:e.target.checked,
    })
  }
  render() {
    const vendorMasterColumns = [
        {
		    title: 'Sr No',
		    dataIndex: 'srNo',
		    key: 'srNo',
		    width: 70
		  },
		  {
		    title: 'Vendor Name',
		    dataIndex: 'vendor_name',
		    key: 'vendor_name',
		    width: 320
		  },
		  {
		    title: 'Address',
		    dataIndex: 'vendor_address',
		    key: 'vendor_address',
		    width: 320
		  },
		  {
		    title: 'Email Address',
		    dataIndex: 'vendor_username',
		    key: 'vendor_username',
		    width: 320
		  },
		  {
		    title: 'Permissions',
		    dataIndex: 'permissions',
		    key: 'permissions',
		    width: 320
		  },
		  {
		    title: 'Is Active?',
		    dataIndex: 'isActive',
		    key: 'isActive',
		    width: 320
		  },
		  {
		    title: 'Action',
		    dataIndex: 'action',
		    key: 'action',
		    width: 320,
            render: (text,record,index) => { 
			    return (
			      <div>
			      	<button className="action_btns" style={{marginRight:5}} onClick={()=>this.editVendor(record)}><i class='bx bx-edit-alt'></i></button>
			      	<button className="action_btns_delete" onClick={()=>this.deleteVendor(record)}><i class='bx bx-trash-alt'></i></button>
			      </div>
			    )
			},
		  },
    ]
    const radioStyle = {
        display: 'inline',
        height: '30px',
        lineHeight: '30px',
        marginLeft: '30px'
      };
    return (
      <div>
          <Header/>
          <div  className="container">
            <div className="header_menu">
                <button style={{marginTop:20}} className="add_new_button" type="button" onClick={this.addNewVendor}>Add New Vendor</button>
            </div>
            <Table 
                style={{marginTop:20}}
                bordered 
                columns={vendorMasterColumns} 
                dataSource={this.state.vendorData}
                />
            </div>
            <Modal
                title='Add Vendor'
                visible={this.state.showAddModal}
                footer={
                  <Button type='primary' onClick={(this.state.isEditing) ? this.updateVendor : this.saveNewVendor} loading={this.state.spinner}>
                    {(this.state.isEditing) ? "Update" : "Add"}
                  </Button>
                }
                onCancel={this.handleAddModalCancel}
            >
                <span>Vendor Name</span>
                <Input
                    placeholder='Vendor Name'
                    name="name"
                    value={this.state.name}
                    onChange={this.handleTextChange}
                />
                <span>Vendor Address</span>
                <Input
                    placeholder='Vendor Address'
                    name="vendor_address"
                    value={this.state.vendor_address}
                    onChange={this.handleTextChange}
                />
                <span>Email Address</span>
                <Input
                    placeholder='Email Address'
                    name="vendor_username"
                    value={this.state.vendor_username}
                    onChange={this.handleTextChange}
                />
                <span>Password</span>
                <Input
                    placeholder='Password'
                    name="vendor_password"
                    type="password"
                    value={this.state.vendor_password}
                    onChange={this.handleTextChange}
                />
                <span>Is Active?</span>
                <Radio.Group onChange={this.onRadioValueChange} value={this.state.isActive}>
                    <Radio style={radioStyle} value="Yes">
                        Yes
                    </Radio>
                    <Radio style={radioStyle} value="No">
                        No
                    </Radio>
                </Radio.Group>
                <br></br>
                <span>Permissions</span>
                <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.frame}
                  name="frame"
                  defaultChecked={this.state.frame}>Frame Details</Checkbox>
                <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.frontWheel}
                  name="frontWheel"
                  defaultChecked={this.state.frontWheel}>Wheel Details</Checkbox>
                  <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.frFairingAssembly}
                  name="frFairingAssembly"
                  defaultChecked={this.state.frFairingAssembly}>Fairing Details</Checkbox>
                <br></br>
                <Checkbox
                  onChange={(e) => this.onChangeCheckbox(e)}
                  checked={this.state.paintedParts}
                  name="paintedParts"
                  defaultChecked={this.state.paintedParts}>Painted Parts Details</Checkbox>
            </Modal>
      </div>
    );
  }
}
export default VendorMaster