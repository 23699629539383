import React, { Component } from "react";


class FrameDetails extends Component {

	state = {

	}


	render() {
		return (
			<section className="__home_section">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 m-auto">
							<p className="heading-text text-center">Bajaj Auto POC Logo</p>
							<h6 className="text-center">Admin Login</h6>

							<div className="form">
								<input type="text" placeholder="Enter Email ID"/><br/>
								<input type="text" placeholder="Enter Password"/><br/>
								<center>
									<button type="button">Login</button>
								</center>
							</div>

						</div>
					</div>
				</div>
			</section>
		)
	}


}

export default FrameDetails;
















