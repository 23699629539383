import { Button, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap';


class Header extends Component {

	state = {
		permissions:"",
		showMasterDataModal:false,
		showWorkOrderDataModal:false
	}
	componentDidMount(){
		var token = localStorage.getItem('token')
		if(!token){
			window.location.assign('/')
		}
		var permissions = localStorage.getItem('permissions')
		this.setState({permissions})
	}
	logout = () => {
		localStorage.clear()
		window.location.assign('/')
	}
	handleAddModalCancel = () => {
		this.setState({showMasterDataModal:false, showWorkOrderDataModal:false})
	}
	render() {
		return (
			<div>

			{/*<section className="__header_section">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-2">
							<img src="./assets/logo.png" alt="logo" />
						</div>
						<div className="col-lg-10 header_menu">
							<a style={{marginRight:10}} href="/ProductPlan">Home</a>
							{(this.state.permissions.includes('Master Data')) ? <a style={{marginRight:10}} onClick={()=>this.setState({showMasterDataModal:true})}>Import Master Data</a> : null}
							{(this.state.permissions.includes('Work Orders')) ? <a style={{marginRight:10}} onClick={()=>this.setState({showWorkOrderDataModal:true})}>Import Work Orders</a> : null}
							{(this.state.permissions.includes('Vendors')) ? <a style={{marginRight:10}} href="/vendorMaster">Vendor Master</a> : null}
							{(this.state.permissions.includes('Admins')) ? <a style={{marginRight:10}} href="/adminMaster">Admin Master</a> : null}
							<button type="button" onClick={this.logout}>Logout</button>
						</div>
					</div>
				</div>
			</section>*/}

			<Navbar expand="lg">
			  <Navbar.Brand href="#home"><img src="./assets/logo.png" style={{ height: 37 }} alt="logo" /></Navbar.Brand>
			  <Navbar.Toggle aria-controls="basic-navbar-nav" />
			  <Navbar.Collapse id="basic-navbar-nav">
			    <Nav className="m-auto">
			      <Nav.Link href="/ProductPlan">Home</Nav.Link>
			      {(this.state.permissions.includes('Master Data')) ? <Nav.Link onClick={()=>this.setState({showMasterDataModal:true})}>Import Master Data</Nav.Link> : null}
			       {(this.state.permissions.includes('Work Orders')) ? <Nav.Link onClick={()=>this.setState({showWorkOrderDataModal:true})}>Import Work Orders</Nav.Link> : null}
			       {(this.state.permissions.includes('Vendors')) ? <Nav.Link  href="/vendorMaster">Vendor Master</Nav.Link> : null}
			       {(this.state.permissions.includes('Admins')) ? <Nav.Link href="/adminMaster">Admin Master</Nav.Link> : null}
			    </Nav>
			    <Form inline>
			      <Button type="button" className="logout-btn" onClick={this.logout}>Logout</Button>
			    </Form>
			  </Navbar.Collapse>
			</Navbar>

			<Modal
                title='Import Master Data'
                visible={this.state.showMasterDataModal}
                footer={
                  <Button type='primary' onClick={this.handleAddModalCancel} loading={this.state.spinner}>
                    Import
                  </Button>
                }
                onCancel={this.handleAddModalCancel}
            >
				<h5>Select File</h5>
				<Input type="file" placeholder="Select File"/>
			</Modal>
			<Modal
                title='Import Work Order Data'
                visible={this.state.showWorkOrderDataModal}
                footer={
                  <Button type='primary' onClick={this.handleAddModalCancel} loading={this.state.spinner}>
                    Import
                  </Button>
                }
                onCancel={this.handleAddModalCancel}
            >

				<h5>Select File</h5>
				<Input type="file" placeholder="Select File"/>
			</Modal>
			</div>
		)
	}

}

export default Header;
















