import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Login from "./screens/Login";
import ProductPlan from "./screens/ProductPlan";
import FrameDetails from "./screens/FrameDetails";
import Home from "./screens/Home";
import VendorMaster from "./screens/VendorMaster";
import AdminMaster from "./screens/AdminMaster";
import { createBrowserHistory } from "history";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {

    return (
      <Router basename="/">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/ProductPlan" component={ProductPlan} />
          <Route exact path="/FrameDetails" component={FrameDetails} />
          <Route exact path="/vendorMaster" component={VendorMaster}/>
          <Route exact path="/adminMaster" component={AdminMaster}/>
          
        </Switch>
      </Router>
    );
  }
}

export default App;
